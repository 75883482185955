import { Platform, View, Text } from "react-native";
import { Button } from "react-native-elements";
import React from "react";
import * as RootNavigation from "./RootNavigation.js";
import { useNavigation } from "@react-navigation/native";
import { FontAwesomeLayer } from "./FontAwesome/FontAwesomeIcon";
import LoginInfo from "./LoginInfo";
import Constants from "expo-constants";
import { Desktop } from "./ResponsiveView/ResponsiveView";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

export default () => {
  const navigation = useNavigation();
  const connectionSuccess = true;
  const connectionFailed = false;
  const connectionProgress = true;
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      {/*
      <FontAwesomeLayer size={24}>
        <FontAwesomeIcon
          icon={["far", "exchange-alt"]}
          color={connectionProgress ? "rgb(255,255,255)" : "rgb(120,120,120)"}
          size={24}
          transform="shrink-4"
        />
        {connectionSuccess && (
          <FontAwesomeIcon
            icon={["far", "check"]}
            color={connectionProgress ? "rgb(120,255,120)" : "rgb(60,120,60)"}
            size={24}
            transform="shrink-8 up-8"
          />
        )}
        {connectionFailed && (
          <FontAwesomeIcon
            icon={["far", "times"]}
            color={connectionProgress ? "rgb(255,120,120)" : "rgb(120,60,60)"}
            size={24}
            transform="shrink-8 up-8 right-3"
          />
        )}
      </FontAwesomeLayer>*/}
      <Button
        onPress={() => navigation.navigate("LoginScreen")}
        type="clear"
        icon={
          <>
            <FontAwesomeIcon
              icon={["fad", "user-lock"]}
              color="white"
              secondaryColor="goldenrod"
              secondaryOpacity={1}
              size={40}
              transform={{ x: 0 }}
            />
          </>
        }
      />
      <Desktop>
        <LoginInfo textStyle={{ fontSize: 10, color: "white" }} />
      </Desktop>
    </View>
  );
};
