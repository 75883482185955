import { reportActions, roleActions } from "../actions";
import * as initial_state_json from "../initial_state.json";

export default function report(
  state = initial_state_json.reports || {},
  action
) {
  switch (action.type) {
    case roleActions.RESET_ROLES:
      return Object.assign({}, action.json.reports);
    case reportActions.SET_REPORTS:
      return Object.assign({}, state, action.reports);
    default:
      return state;
  }
}
