import React from "react";
import { View } from "react-native";
import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

const getColumns = (width: number) => {
  const screenWidth = window.innerWidth;
  const growthFactor = Math.max(screenWidth / width - 1, 1) * 20;
  return Math.max(Math.floor(screenWidth / (width + growthFactor)), 1);
};

export { Desktop, Tablet, Mobile, Default, getColumns };
