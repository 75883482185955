import Team from "../classes/Team";
import { ServerState } from "../types";

export const ADD_TEAM = "ADD_TEAM";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const REMOVE_TEAM = "REMOVE_TEAM";
export const FETCH_TEAMS_SUCCESS = "SERVER_FETCH_TEAMS_SUCCESS";
export const FETCH_TEAMS_ERROR = "SERVER_FETCH_TEAMS_ERROR";
export const FETCH_TEAM_BY_ID_SUCCESS = "FETCH_TEAM_BY_ID_SUCCESS";
export const FETCH_TEAM_BY_ID_ERROR = "FETCH_TEAM_BY_ID_ERROR";
export const INSERT_TEAM_SUCCESS = "INSERT_TEAM_SUCCESS";
export const INSERT_TEAM_ERROR = "INSERT_TEAM_ERROR";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_ERROR = "UPDATE_TEAM_ERROR";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_ERROR = "DELETE_TEAM_ERROR";

export function addTeam(team: Team) {
  return { type: ADD_TEAM, team };
}

export function updateTeam(team: Team) {
  return { type: UPDATE_TEAM, team };
}

export function removeTeam(team: Team) {
  return { type: REMOVE_TEAM, team };
}

export function fetchTeamsSuccess(state: ServerState) {
  return { type: FETCH_TEAMS_SUCCESS, state };
}

export function fetchTeamsError(state: ServerState) {
  return { type: FETCH_TEAMS_ERROR, state };
}

export function fetchTeamByIdSuccess(state: ServerState) {
  return { type: FETCH_TEAM_BY_ID_SUCCESS, state };
}

export function fetchTeamByIdError(state: ServerState) {
  return { type: FETCH_TEAM_BY_ID_ERROR, state };
}

export function updateTeamSuccess(state: ServerState) {
  return { type: UPDATE_TEAM_SUCCESS, state };
}

export function updateTeamError(state: ServerState) {
  return { type: UPDATE_TEAM_ERROR, state };
}

export function insertTeamSuccess(state: ServerState) {
  return { type: INSERT_TEAM_SUCCESS, state };
}

export function insertTeamError(state: ServerState) {
  return { type: INSERT_TEAM_ERROR, state };
}

export function deleteTeamSuccess(state: ServerState) {
  return { type: DELETE_TEAM_SUCCESS, state };
}

export function deleteTeamError(state: ServerState) {
  return { type: DELETE_TEAM_ERROR, state };
}
