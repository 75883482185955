import { sessionActions } from "../actions";

export const SET_SESSION = "SET_SESSION";
export const AUTHENTICATED_SESSION_PRE_START =
  "AUTHENTICATED_SESSION_PRE_START";
export const AUTHENTICATED_SESSION_POST_START =
  "AUTHENTICATED_SESSION_POST_START";
export const AUTHENTICATED_SESSION_PRE_END = "AUTHENTICATED_SESSION_PRE_END";
export const AUTHENTICATED_SESSION_POST_END = "AUTHENTICATED_SESSION_POST_END";

export function setSession(session) {
  return { type: SET_SESSION, session };
}

export function startAuthenticatedSession(username, uuid, code) {
  return {
    type: AUTHENTICATED_SESSION_PRE_START,
    username: username,
    uuid: uuid,
    code: code,
  };
}
