import React from "react";
import { Text, View, ActivityIndicator } from "react-native";
import LogoWhite from "../components/LogoWhite";

const MyTheme = {
  dark: false,
  colors: {
    primary: "rgb(255, 45, 85)",
    background: "rgb(244, 242, 242)", // screen background
    card: "rgb(48, 64, 128)", // header and tabbar
    text: "rgb(255, 255, 255)",
    border: "rgb(199, 199, 204)",
  },
};

export default class PersistLoadingScreen extends React.Component<any, any> {
  render() {
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(48, 64, 128)",
        }}
      >
        <LogoWhite />
        <ActivityIndicator size="large" color="white" />
        <View>
          <Text style={{ color: "white" }}>Loading</Text>
        </View>
      </View>
    );
  }
}
