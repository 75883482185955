import * as drawerActions from "./actions/drawer";
import * as userActions from "./actions/user";
import * as playerActions from "./actions/player";
import * as teamActions from "./actions/team";
import * as inviteActions from "./actions/invite";
import * as userformActions from "./actions/userform";
import * as roleActions from "./actions/role";
import * as standardformActions from "./actions/standardform";
import * as organizationActions from "./actions/organization";
import * as sessionActions from "./actions/session";
// import * as networkActions from "./actions/network";
import * as themeActions from "./actions/theme";
import * as respondentActions from "./actions/respondent";
import * as reportActions from "./actions/report";
import * as answerActions from "./actions/answer";

// initial state
import * as initial_state_json from "./initial_state.json";
/*
import datastore from "./datasource";

function fetchAccountDataFromServer(uri = "") {
  if (uri === "") {
    return fetch(datastore.get().uri);
  } else {
    return fetch(uri);
  }
}

export function fetchAccountData(uri = "") {
  // https://github.com/reduxjs/redux-thunk
  return (dispatch) => {
    fetchAccountDataFromServer(uri)
      .then(
        (response) => {
          if (response.ok) return response.json();
          else throw new Error(JSON.stringify(response));
        },
        (response) => {
          alert(JSON.stringify(response));
          throw new Error("HTTP error " + response.status);
        }
      )
      .then((json) => {
        dispatch(drawerActions.setDrawerItems(json.drawer));
        dispatch(userActions.setUser(json.user));
        dispatch(organizationActions.setOrganization(json.organization));
        dispatch(teamActions.setTeams(json.teams));
        dispatch(playerActions.setPlayers(json.players));
        dispatch(formActions.setForms(json.userforms));
        dispatch(inviteActions.setInvites(json.invites));
      })
      .catch((err) => {
        alert(err);
      });
  };
}
*/
const LOG_OUT = "LOG_OUT";
export function logOut() {
  return (dispatch) => {
    dispatch({
      type: LOG_OUT,
      user: {
        currentId: 0,
      },
      roles: {
        0: {
          username: "",
          displayname: "",
          role: "none",
          rolename: "none",
          teams: [],
          invites: [],
          menu: [],
        },
      },
      teams: {},
      players: {},
      forms: {},
      organization: {},
      drawer: {
        items: {},
      },
    });
  };
}

const RESET_STATE = "RESET_STATE";
function resetState() {
  return (dispatch) => {
    dispatch({ type: RESET_STATE, state: initial_state_json });
  };
}

export {
  drawerActions,
  userActions,
  roleActions,
  organizationActions,
  teamActions,
  playerActions,
  reportActions,
  respondentActions,
  answerActions,
  inviteActions,
  userformActions,
  standardformActions,
  sessionActions,
  //  networkActions,
  themeActions,
  RESET_STATE,
  LOG_OUT,
  resetState,
};
