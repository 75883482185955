import { userActions, roleActions } from "../actions";
import * as initial_state_json from "../initial_state.json";

function assert(param, format) {
  if (typeof format == "object") {
    for (const key of Object.keys(format)) {
      if (typeof param[key] == "undefined") {
        console.warn("reducers/user.js: " + key + " missing (undefined)");
        console.log(param);
        return false;
      }
      if (typeof param[key] == "object") {
        if (!assert(param[key], format[key])) return false;
      }
    }
  }
  return true;
}

export default function user(state = initial_state_json.user || {}, action) {
  switch (action.type) {
    case roleActions.RESET_ROLES:
      if (typeof action.json.roles[state.currentId] === "undefined") {
        return Object.assign({}, state, {
          currentId: Object.keys(action.json.roles)[0],
        });
      }
      return state;
    case userActions.SET_CURRENT_ROLE:
      debugger;
      return Object.assign({}, state, { currentId: action.roleUuid });

    case userActions.SET_USER:
      return Object.assign({}, state, action.user);

    case userActions.AUTHORIZE_SUCCESS:
      return state;

    case userActions.AUTHORIZE_FAIL:
      return state;

    case userActions.SET_ACCESS_TOKEN:
      if (typeof action.payload == "undefined") {
        console.warn(
          "reducers/user.js->SET_CURRENT_ROLE: payload missing (undefined)"
        );
        return state;
      }
      let expiration_time = action.payload.expires_in
        ? Date.now() + action.payload.expires_in * 1000
        : 0;
      return Object.assign({}, state, {
        /*
          const access_token = action.payload.access_token;
          const expires_in = action.payload.expires_in;
          const refresh_token = action.payload.refresh_token;
          const token_type = action.payload.token_type;
        */
        oauth: {
          auth_method: "oauth2",
          refresh_token: action.payload.refresh_token,
          access_token: action.payload.access_token,
          auth_token: null,
          token_type: action.payload.token_type,
          access_token_expiration: action.payload.expires_in,
          access_token_expires: expiration_time,
        },
      });

    default:
      return state;
  }
  return state;
}
