import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faYoutube,
  faVimeo,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare as faCheckSquareLight,
  faExclamation as faExclamationLight,
  faExchangeAlt as faExchangeAltLight,
  faUser as faUserLight,
  faUserLock as faUserLockLight,
  faUserEdit as faUserEditLight,
  faUserTie as faUserTieLight,
  faUsers as faUsersLight,
  faChevronDown as faChevronDownLight,
  faChevronUp as faChevronUpLight,
  faChevronLeft as faChevronLeftLight,
  faChevronRight as faChevronRightLight,
  faCheck as faCheckLight,
  faTimes as faTimesLight,
} from "@fortawesome/pro-regular-svg-icons"; // "@fortawesome/pro-light-svg-icons";

import {
  faUser as faUserSolid,
  faUserLock as faUserLockSolid,
  faChevronDown as faChevronDoenSolid,
  faChevronUp as faChevronUpSolid,
  faChevronLeft as faChevronLeftSolid,
  faChevronRight as faChevronRightSolid,
  faTshirt as faTshirtSolid,
} from "@fortawesome/pro-solid-svg-icons"; // "@fortawesome/pro-light-svg-icons";

//import { faCheck as faCheckCustom } from "./custom-duotone-icons";
import {
  _0 as _0_S,
  _1 as _1_S,
  _2 as _2_S,
  _3 as _3_S,
  _4 as _4_S,
  _5 as _5_S,
  _6 as _6_S,
  _7 as _7_S,
  _8 as _8_S,
  _9 as _9_S,
} from "./custom-solid-icons";

import {
  faFieldHockey as faFieldHockeyDuo,
  faUserLock as faUserLockDuo,
} from "@fortawesome/pro-duotone-svg-icons";

export function ImportFontawesomeIcons() {
  library.add(
    faYoutube,
    faVimeo,
    faWhatsapp,

    faCheckSquareLight,
    faExclamationLight,
    faExchangeAltLight,
    faUserLight,
    faUserLockLight,
    faUserEditLight,
    faUserTieLight,
    faUsersLight,
    faChevronDownLight,
    faChevronUpLight,
    faChevronLeftLight,
    faChevronRightLight,
    faCheckLight,
    faTimesLight,
    faUserSolid,
    faUserLockSolid,
    faChevronDoenSolid,
    faChevronUpSolid,
    faChevronLeftSolid,
    faChevronRightSolid,
    faTshirtSolid,

    faFieldHockeyDuo,
    faUserLockDuo,

    //    faCheckCustom,
    _0_S,
    _1_S,
    _2_S,
    _3_S,
    _4_S,
    _5_S,
    _6_S,
    _7_S,
    _8_S,
    _9_S
  );
}
