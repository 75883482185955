export const SET_THEME = "SET_THEME";

export const THEMES = {
  SPORTMONITOR: "sportmonitor",
  DARK: "dark",
};

export function setTheme(key) {
  return { type: SET_THEME, theme: key };
}
