import { drawerActions } from "../actions";

export default function drawer(
  state: any = [],
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case drawerActions.ADD_ITEM:
      return [
        ...state,
        {
          key: action.payload.key,
          title: action.payload.title,
          description: action.payload.description,
          icon: action.payload.icon,
          badge: action.payload.badge,
          active: action.payload.active,
        },
      ];
    case drawerActions.SET_BADGE:
      return state.map((drawerItem) => {
        if (drawerItem.key === action.payload.key) {
          return Object.assign({}, drawerItem, {
            badge: action.payload.badgeText,
          });
        }
        return drawerItem;
      });
    case drawerActions.SET_ITEMS:
      //            alert(JSON.stringify(action.drawer.items));
      return Object.assign({}, state, { items: action.payload.items });
    default:
      return state;
  }
}
