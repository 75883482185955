import * as React from "react";
import * as WebBrowser from "expo-web-browser";
import {
  makeRedirectUri,
  useAuthRequest,
  ResponseType,
} from "expo-auth-session";
import { Button } from "react-native";
import Constants from "expo-constants";
import { connect } from "react-redux";
import { RootState } from "../types";
import { userActions } from "../actions";
import * as server from "./server";
import { getAccessToken, getRefreshToken } from "./server";

WebBrowser.maybeCompleteAuthSession();

// Endpoint
/*const discovery_github = {
  authorizationEndpoint: "https://github.com/login/oauth/authorize",
  tokenEndpoint: "https://github.com/login/oauth/access_token",
  revocationEndpoint:
    "https://github.com/settings/connections/applications/<CLIENT_ID>",
};
*/

// Endpoint
const standalone = false;
const client_id =
  Constants.appOwnership == null || Constants.appOwnership == "expo"
    ? Constants.platform.web
      ? "sportmonitor_webapp_test"
      : "sportmonitor_native_test"
    : Constants.platform.web
    ? "sportmonitor_webapp"
    : "sportmonitor_native";
const client_secret = "";
const discovery_sportmonitor = {
  authorizationEndpoint: "https://testapi.sportmonitor.net/authorize/login",
  tokenEndpoint: "https://testapi.sportmonitor.net/access_token",
  revocationEndpoint:
    "https://testapi.sportmonitor.net/oauth/revoke/" + client_id,
};
const redirectUri = makeRedirectUri(
  standalone
    ? {
        // For usage in bare and standalone
        native: "sportmonitor://redirect",
      }
    : {
        // published project in the Expo Client App
        // Development project in the Expo Client App
        useProxy: false,
      }
);

export function refreshAccessToken(onSuccess, onError): void {
  debugger;
  console.log("requestAccessToken");
  getRefreshToken().then((token) => {
    const form = new FormData();
    form.append("grant_type", "refresh_token");
    form.append("client_id", client_id);
    form.append("redirect_uri", redirectUri);
    form.append("client_secret", client_secret);
    form.append("refresh_token", token);
    server.api_call({
      endpoint: "access_token",
      init: {
        method: "POST",
        //redirect: "follow",
        //mode: "cors",
        // cache: "no-cache",
        //     referrerPolicy: "no-referrer",
        body: form,
        headers: {
          Accept: "application/json",
          //        ContentType: "application/json",
        },
      },
      onSuccess: onSuccess,
      onError: onError,
    });
  });
}

export function requestAccessToken(
  response,
  request,
  onSuccess,
  onError
): void {
  console.log("requestAccessToken");
  const form = new FormData();
  form.append("grant_type", "authorization_code");
  form.append("client_id", client_id);
  form.append("redirect_uri", redirectUri);
  form.append("code", response.params.code);
  form.append("code_verifier", request.codeVerifier);
  form.append("client_secret", client_secret);
  server.api_call({
    endpoint: "access_token",
    init: {
      method: "POST",
      //redirect: "follow",
      //mode: "cors",
      // cache: "no-cache",
      //     referrerPolicy: "no-referrer",
      body: form,
      headers: {
        Accept: "application/json",
        //        ContentType: "application/json",
      },
    },
    onSuccess: onSuccess,
    onError: onError,
  });
}

function OAuthComponent(props) {
  const { userRequestAccessToken } = props;
  const [request, response, promptAsync] = useAuthRequest(
    {
      responseType: ResponseType.Code,
      clientId: client_id,
      scopes: ["sportmonitor"],
      // For usage in managed apps using the proxy
      // https://docs.expo.io/guides/authentication/
      redirectUri: redirectUri,
    },
    discovery_sportmonitor
  );

  React.useEffect(() => {
    console.log("App effect");
    if (Constants.platform.android) WebBrowser.warmUpAsync().then();
    if (response && response.type === "success") {
      console.log(typeof response.params.code);
      if (typeof response.params.code === "string") {
        // Authorization code received
        console.log("Authorization code received");
        userActions.oauthRequestAccessToken(response, request);
        //        userRequestAccessToken(response, request);
        // Exchange for an access code
      }
      //const auth = response.params;
      //const token = response.params.access_token;
      //redux store save(auth)
      console.log("Auth response success");
      console.log(response);
    }
    return () => {
      if (Constants.platform.android) WebBrowser.coolDownAsync().then();
    };
  }, [response]);

  return (
    <Button
      disabled={!request}
      title="Login"
      onPress={() => {
        promptAsync().then();
      }}
    />
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    oauth: state.user.oauth,
  };
};

const mapDispatch = (dispatch) => {
  return {
    //    userRequestAccessToken: (response, request) => dispatch(userActions.oauthRequestAccessToken(response, request)),
  };
};

export let OAuth = connect(mapStateToProps, mapDispatch)(OAuthComponent);
