import { userformActions, roleActions } from "../actions";
import * as initial_state_json from "../initial_state.json";

const initialstate = initial_state_json.userforms || {};
export default function userform(state = initialstate, action) {
  switch (action.type) {
    case roleActions.RESET_ROLES:
      return action.json.userforms;
    case userformActions.SET_USERFORMS:
      return Object.assign({}, state, action.userforms);
    default:
      return state;
  }
}
