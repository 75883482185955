import { createAction } from "typesafe-actions";

import datastore from "../datasource";

/*
 * action types
 */

export const ADD_ITEM = "ADD_ITEM";
export const SET_BADGE = "SET_BADGE";
export const SET_ITEMS = "SET_ITEMS";

/*
 * other constants
 */

/*
 * action creators
 */

/*
export function setDrawerItems(drawer) {
    return { type: SET_ITEMS, drawer }
}
export function setDrawerItemBadge(key, badgeText) {
    return { type: SET_BADGE, key, badgeText }
}
*/

export const addItem = createAction(ADD_ITEM)<{
  key: number;
  title: string;
  description: string;
  icon: string;
  badge: number;
  active: boolean;
}>();
export const setDrawerItems = createAction(SET_ITEMS)<{}>();
export const setDrawerItemBadge = createAction(SET_BADGE)<{
  key: number;
  badgeText: string;
}>();

function fetchMenuTabsFromServer() {
  //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  //return fetch('https://ontwikkel.sportmonitor.net/static_api/menu_tabs.php');
  //    alert (datastore.get());
  return fetch(datastore.get() /*'/static_api/static_api_data.json'*/);
  /*
        return fetch('https://ontwikkel.sportmonitor.net/static_api/todo_example.json', {
            credentials: 'same-origin'
        });
    */
}

export function initMenuTabs() {
  // https://github.com/reduxjs/redux-thunk
  return (dispatch) => {
    fetchMenuTabsFromServer()
      .then(
        (response) => {
          if (response.ok) return response.json();
          else throw new Error(JSON.stringify(response));
        },
        (response) => {
          alert(JSON.stringify(response));
          throw new Error("HTTP error " + response.status);
        }
      )
      .then((json) => {
        dispatch(setDrawerItems(json.drawer));
      })
      .catch((err) => {
        alert(err);
      });
  };
}
