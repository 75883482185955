import { respondentActions, roleActions } from "../actions";
import * as initial_state_json from "../initial_state.json";

export default function respondent(
  state = initial_state_json.respondents || {},
  action
) {
  switch (action.type) {
    case roleActions.RESET_ROLES:
      return Object.assign({}, action.json.respondents);
    case respondentActions.SET_RESPONDENTS:
      return Object.assign({}, state, action.respondents);
    default:
      return state;
  }
}
