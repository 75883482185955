import Player from "../classes/Player";

export const ADD_PLAYER = "ADD_PLAYER";
export const UPDATE_PLAYER = "UPDATE_PLAYER";
export const REMOVE_PLAYER = "REMOVE_PLAYER";

export function addPlayer(player: Player) {
  return { type: ADD_PLAYER, player };
}

export function updatePlayer(player: Player) {
  return { type: UPDATE_PLAYER, player };
}

export function removePlayer(player: Player) {
  return { type: REMOVE_PLAYER, player };
}
