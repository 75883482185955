import AsyncStorage from "@react-native-community/async-storage";
import { reduxStore } from "../store";
import { userActions } from "../actions";
import { refreshAccessToken } from "./oauth";

export enum apiResource {
  "role" = "roles",
  "answer" = "answers",
  "invite" = "invites",
  "report" = "reports",
  "player" = "players",
  "team" = "teams",
  "respondent" = "respondents",
  "userform" = "userforms",
  "standardform" = "standardforms",
  "scoutreport" = "scoutreports",
  "account" = "accounts",
  "organization" = "organizations",
}

export interface onResponseHandler {
  (result: apiResult, params: object): void;
}
export interface apiRequestHandler {
  onSuccess?: {
    // generic success handler: status codes 2xx
    action: string | onResponseHandler;
    params?: object;
  };
  onError?: {
    // generic error handler: status codes 3xx, 4xx, 5xx
    action: string | onResponseHandler;
    params?: object;
  };
  onResponseStatus?: {
    // Takes precedence over onSuccess and onError
    status: number;
    action: string | onResponseHandler;
    params?: object;
  }[];
}

interface baseApiRequest {
  endpoint: string;
  init: object;
  id?: string;
  queryParams?: object;
  bodyParams?: object;
}

interface apiRequest extends baseApiRequest, apiRequestHandler {}

export interface apiResult {
  json: Promise<Object>;
  success: boolean;
  response: Response;
  errorMessage?: string;
}

export async function clearAccessToken() {
  console.log("Clear Token");
  try {
    await AsyncStorage.multiRemove([
      "@spm_token",
      "@spm_refresh",
      "@spm_expires",
    ]);
  } catch (e) {
    // saving error
    console.log("Clearing Error");
  }
  console.log("Access token cleared");
}

export async function setAccessToken(
  accesstoken: string,
  refresh_token?: string,
  expires_in?: number
) {
  console.log("Set Token: " + accesstoken);
  try {
    await AsyncStorage.multiSet([
      ["@spm_token", accesstoken],
      ["@spm_refresh", refresh_token],
      ["@spm_expires", (expires_in * 1000 + Date.now()).toString()],
    ]);
  } catch (e) {
    // saving error
    console.log("Saving Error");
  }
  console.log("Value saved:");
  console.log(accesstoken);
}

export async function getAccessToken() {
  try {
    let accesstoken = await AsyncStorage.getItem("@spm_token");
    if (accesstoken == null) {
      // no stored value
      console.log("Value not found");
      return null;
    }
    console.log("Value read:");
    console.log(accesstoken);
    return accesstoken;
  } catch (e) {
    // error reading value
    console.log("Reading Error");
  }
}

export async function getRefreshToken() {
  try {
    let accesstoken = await AsyncStorage.getItem("@spm_refresh");
    if (accesstoken == null) {
      // no stored value
      console.log("Value not found");
      return null;
    }
    console.log("Value read:");
    console.log(accesstoken);
    return accesstoken;
  } catch (e) {
    // error reading value
    console.log("Reading Error");
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
export function api_call(apiRequest) {
  console.log("Fetch (" + apiRequest.init.method + "): " + apiRequest.endpoint);
  fetch(
    "https://testapi.sportmonitor.net/" + apiRequest.endpoint,
    apiRequest.init
  )
    .then((response) => {
      if (response.ok) {
        if (
          typeof apiRequest.onSuccess !== "undefined" &&
          typeof apiRequest.onSuccess.action === "function"
        ) {
          apiRequest.onSuccess.action(response, apiRequest.onSuccess.params);
        } else if (
          typeof apiRequest.onSuccess !== "undefined" &&
          typeof apiRequest.onSuccess.action === "string"
        ) {
          response.json().then((json) => {
            reduxStore.dispatch({
              type: apiRequest.onSuccess.action,
              json: json,
              params: apiRequest.onSuccess.params,
            });
          });
        } else {
          onResponse(response, apiRequest);
        }
        //      } else if (response.status == 401) {
        //        // access_denied
      } else {
        /* API errors:
          400 invalid_request // The request is missing a required parameter, includes an invalid parameter value, includes a parameter more than once, or is otherwise malformed.
          400 invalid_scope
          400 invalid_grant
          401 invalid_client
          401 invalid_credentials  // The user credentials were incorrect.
          401 invalid_request  // request toke invalid
          401 access_denied  // The resource owner or authorization server denied the request.
          404 not_found
          500 server_error
          */
        response.json().then((json) => {
          if (response.status == 401 && json.error == "access_denied") {
            // invalid access token. Refresh and try again
            userActions.oauthRefreshAccessToken();
          } else {
            alert(
              json.error +
                " " +
                response.status +
                "\n" +
                json.error_description +
                "\n" +
                json.hint
            );
          }
        });
      }
    })
    .catch((error) => {
      if (
        typeof apiRequest.onError !== "undefined" &&
        typeof apiRequest.onError === "function"
      ) {
        apiRequest.onError.action(error, apiRequest.onError.params);
      } else if (
        typeof apiRequest.onError !== "undefined" &&
        typeof apiRequest.onError === "string"
      ) {
        reduxStore.dispatch({
          type: apiRequest.onError.action,
          error: error,
          params: apiRequest.onError.params,
        });
      } else {
        onError(error, apiRequest);
      }
    });
}

function objectToQueryString(params: {}): string {
  if (params == null || params === {}) return "";
  return Object.keys(params)
    .map(
      (key) =>
        `${encodeURIComponent(key.toLowerCase())}=${encodeURIComponent(
          params[key]
        )}`
    )
    .join("&");
}

function objectToBodyString(params: {}): string {
  if (params == null || params === {}) return "";
  return JSON.stringify(params);
}

function handleTokenError() {
  console.log("Token not set"); // saving error
  alert("Token error");
  return null;
}

// HTTP RESPONSES
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Status

interface responseStatusHandlerFunction {
  (response: Response, request: apiRequest): apiResult;
}
const responseStatusHandler: responseStatusHandlerFunction[] = [];

// INFORMATION RESPONSENSES
responseStatusHandler[100] = (
  response: Response
  //  request: apiRequest
): apiResult => {
  // Generic information Handler
  return {
    json: response.json(),
    success: false,
    response: response,
  };
};

// SUCCESSFUL RESPONSES
responseStatusHandler[200] = (
  response: Response
  //  request: apiRequest
): apiResult => {
  // OK
  // Resource read and returned (GET)
  // Resource is modified and returned (PUT)
  // Resource has been deleted. The response message includes a representation describing the status. (DELETE)
  return {
    json: response.json(),
    success: true,
    response: response,
  };
};

responseStatusHandler[201] = (
  response: Response,
  request: apiRequest
): apiResult => {
  // Created
  // Resource did not exist. It is created and returned (PUT)
  return responseStatusHandler[200](response, request);
};

responseStatusHandler[202] = (
  response: Response
  //  request: apiRequest
): apiResult => {
  // Accepted: the action will likely succeed but has not yet been enacted. (DELETE)
  return {
    json: null,
    success: true,
    response: response,
  };
};

responseStatusHandler[203] = (
  response: Response,
  request: apiRequest
): apiResult => {
  // Non-Authoritative Information
  // This response code means the returned meta-information is not exactly the same as is available from the origin server,
  // but is collected from a local or a third-party copy.
  return responseStatusHandler[200](response, request);
};

responseStatusHandler[204] = (
  response: Response
  //  request: apiRequest
): apiResult => {
  // No content
  // Resource did exist. It is modified and not returned (PUT)
  // Resource did exist. It is deleted (and not returned) (DELETE)
  return {
    json: null,
    success: true,
    response: response,
  };
};

responseStatusHandler[205] = (
  response: Response,
  request: apiRequest
): apiResult => {
  // Reset Content
  // Tells the user-agent to reset the document which sent this request.
  return responseStatusHandler[200](response, request);
};

responseStatusHandler[206] = (
  response: Response,
  request: apiRequest
): apiResult => {
  // Partial Content
  // This response code is used when the Range header is sent from the client to request only part of a resource.
  return responseStatusHandler[200](response, request);
};

// REDIRECTION RESPONSES
// not implemented

// CLIENT ERROR RESPONSES
responseStatusHandler[400] = (
  response: Response
  //  request: apiRequest
): apiResult => {
  // Bad Request
  // The server could not understand the request due to invalid syntax.
  return {
    json: null,
    success: false,
    response: response,
    errorMessage: "Bad Request",
  };
};

responseStatusHandler[401] = (
  response: Response
  //  request: apiRequest
): apiResult => {
  // Unauthorized
  // The client must authenticate itself to get the requested response.
  return {
    json: null,
    success: false,
    response: response,
    errorMessage: "Authentication required",
  };
};

responseStatusHandler[403] = (
  response: Response
  //  request: apiRequest
): apiResult => {
  // Forbidden
  // The authenticated client does not have access rights to the content.
  return {
    json: null,
    success: false,
    response: response,
    errorMessage: "Forbidden",
  };
};

responseStatusHandler[404] = (
  response: Response
  //  request: apiRequest
): apiResult => {
  // Not Found
  // The server can not find the requested resource.
  // OR the endpoint is valid but the resource itself does not exist.
  return {
    json: null,
    success: false,
    response: response,
    errorMessage: "Not Found",
  };
};

responseStatusHandler[405] = (
  response: Response
  //  request: apiRequest
): apiResult => {
  // Method not allowed
  // The request method is known by the server but has been disabled and cannot be used. For example, the API may forbid DELETE-ing a resource.
  return {
    json: null,
    success: false,
    response: response,
    errorMessage: "Method Not Allowed",
  };
};

responseStatusHandler[426] = (
  response: Response
  //  request: apiRequest
): apiResult => {
  // Upgrade required
  // The server refuses to perform the request using the current protocol but might be willing to do so after the client upgrades to a different protocol.
  // The server sends an Upgrade header in a 426 response to indicate the required protocol(s).
  return {
    json: null,
    success: false,
    response: response,
    errorMessage: "Upgrade Required",
  };
};

responseStatusHandler[429] = (
  response: Response
  //  request: apiRequest
): apiResult => {
  // To many requests
  // The user has sent too many requests in a given amount of time ("rate limiting").
  return {
    json: null,
    success: false,
    response: response,
    errorMessage: "Too many requests",
  };
};

// SERVER ERROR RESPONSES
responseStatusHandler[500] = (
  response: Response
  //  request: apiRequest
): apiResult => {
  // Internal server Error
  // The server has encountered a situation it doesn't know how to handle.
  return {
    json: null,
    success: false,
    response: response,
    errorMessage: "Internal Server Error",
  };
};

function onResponse(response, request: apiRequest) {
  if (typeof responseStatusHandler[response.status] === "function") {
    return responseStatusHandler[response.status](response, request);
  } else {
    return {
      json: response.json(),
      response: response,
      success: response.ok,
    };
  }
}

function onError(error, request: apiRequest) {
  //todo handle this more gracefully
  console.log("Fetch failed: " + error.message);
  console.log(request);
}

export function resourceGet(
  resource: apiResource,
  id = null,
  queryParams: {} = null,
  onResponse: apiRequestHandler = null
): void {
  getAccessToken().then((token) => {
    if (token == null) handleTokenError();
    const queryString = objectToQueryString(queryParams);
    let endpoint = resource.toString();
    if (null !== id && typeof id !== "undefined") endpoint += "/" + id;
    if ("" !== queryString) endpoint += "?" + queryString;
    //   let logged_in = oauth && typeof oauth.access_token == "string";
    //   let expired = logged_in && oauth.access_token_expires > Date.now - 5000; // leave a 5 sec window

    api_call({
      endpoint: endpoint,
      init: {
        credentials: "include",
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
      },
      ...onResponse,
    });
  });
}

export function resourcePost(
  resource: apiResource,
  id = null,
  queryParams: {} = null,
  bodyParams: {} = null,
  onResponse: apiRequestHandler = null
): void {
  getAccessToken().then((token) => {
    if (token == null) handleTokenError();
    const queryString = objectToQueryString(queryParams);
    const bodyString = objectToBodyString(bodyParams);
    let endpoint = resource.toString();
    if (null !== id) endpoint += "/" + id;
    if ("" !== queryString) endpoint += "?" + queryString;
    api_call({
      endpoint: endpoint,
      credentials: "include",
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        ContentType: "application/json",
      },
      body: bodyString,
      ...onResponse,
    });
  });
}

/*
If the target resource does not have a current representation and the PUT request successfully creates one,
then the origin server must inform the user agent by sending a 201 (Created) response.
    HTTP/1.1 201 Created
    Content-Location: /new.html

If the target resource does have a current representation and that representation is successfully modified
in accordance with the state of the enclosed representation, then the origin server must send either a 200 (OK)
or a 204 (No Content) response to indicate successful completion of the request.
    HTTP/1.1 204 No Content
    Content-Location: /existing.html
*/
export function resourcePut(
  resource: apiResource,
  id = null,
  queryParams: {} = null,
  bodyParams: {} = null,
  onResponse: apiRequestHandler = null
): void {
  getAccessToken().then((token) => {
    if (token == null) handleTokenError();
    const queryString = objectToQueryString(queryParams);
    const bodyString = objectToBodyString(bodyParams);
    let endpoint = resource.toString();
    if (null !== id) endpoint += "/" + id;
    if ("" !== queryString) endpoint += "?" + queryString;
    api_call({
      endpoint: endpoint,
      credentials: "include",
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        ContentType: "application/json",
      },
      body: bodyString,
      ...onResponse,
    });
  });
}
/*
 If a DELETE method is successfully applied, there are several response status codes possible:
 A 202 (Accepted) status code if the action will likely succeed but has not yet been enacted.
 A 204 (No Content) status code if the action has been enacted and no further information is to be supplied.
 A 200 (OK) status code if the action has been enacted and the response message includes a representation describing the status.
*/
export function resourceDelete(
  resource: apiResource,
  id = null,
  queryParams: {} = null,
  bodyParams: {} = null,
  onResponse: apiRequestHandler = null
): void {
  getAccessToken().then((token) => {
    if (token == null) handleTokenError();
    const queryString = objectToQueryString(queryParams);
    const bodyString = objectToBodyString(bodyParams);
    let endpoint = resource.toString();
    if (null !== id) endpoint += "/" + id;
    if ("" !== queryString) endpoint += "?" + queryString;
    api_call({
      endoint: endpoint,
      credentials: "include",
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        ContentType: "application/json",
      },
      body: bodyString,
      ...onResponse,
    });
  });
}
