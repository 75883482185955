import { themeActions } from "../redux/actions";
import { reduxStore } from "../redux/store";
import { RootState } from "../redux/types";
import { merge } from "lodash-es";
import { Platform } from "react-native";

const sportmonitorBlue = "#003463";
const sportmonitorOrange = "#fb5a00";
const white = "#FFF";
const black = "#000";
const errorDark = "#8B0000";
const errorLight = "#FF6347";
const warningDark = "#666804";
const warningLight = "#EAEB5E";
const successDark = "#006400";
const successLight = "#90EE90";

export interface themeType {
  name: string;
  colors: {
    primaryColor: string;
    primaryContrast: string;
    secondaryColor: string;
    secondaryContrast: string;
    backgroundColor: string;
    textColor: string;
    tabColor: string;
    tabColorSelected: string;
    tabBackground: string;
    drawerColor: string;
    drawerColorSelected: string;
    drawerBackground: string;
    errorColor: string;
    errorBackground: string;
    warningColor: string;
    warningBackground: string;
    noticeColor: string;
    noticeBackground: string;
    successColor: string;
    succesBackground: string;
    modalLayer: string;
    modalBackground: string;
    modalColor: string;
    modalShadow: string;
    gradientTop: string;
    gradientBottom: string;
  };
  style: {
    button: {};
    buttonDisabled: {};
    text: {};
    paragraph: {};
    title: {};
    subtitle: {};
    itemtitle: {};
    itemsubtitle: {};
    itemtext: {};
    textDisabled: {};
    link: {};
    tabBarButton: {};
    drawerButton: {};
    tab: {};
    modal: {
      background: {};
      message: {};
      text: {};
      title: {};
    };
  };
}

const themeBlueprint: themeType = {
  name: "Default",
  colors: {
    primaryColor: sportmonitorBlue,
    primaryContrast: white,
    secondaryColor: sportmonitorOrange,
    secondaryContrast: white,
    backgroundColor: white,
    textColor: black,
    tabColor: sportmonitorBlue,
    tabColorSelected: sportmonitorOrange,
    tabBackground: white,
    drawerColor: sportmonitorBlue,
    drawerColorSelected: sportmonitorOrange,
    drawerBackground: white,
    errorColor: errorDark,
    errorBackground: errorLight,
    warningColor: warningDark,
    warningBackground: warningLight,
    noticeColor: sportmonitorBlue,
    noticeBackground: white,
    successColor: successDark,
    succesBackground: successLight,
    modalLayer: "rgba(25,25,25,0.4)",
    modalBackground: "rgba(25,25,25,0.9)",
    modalColor: "#EEE",
    modalShadow: black,
    gradientTop: "#e5f3ff",
    gradientBottom: "rgba(255, 255, 255, 1)",
  },
  style: {
    button: Platform.select({
      ios: {},
      android: {
        elevation: 4,
        // Material design blue from https://material.google.com/style/color.html#color-color-palette
        backgroundColor: "#2196F3",
        borderRadius: 2,
      },
      web: {
        backgroundColor: "#2196F3",
        borderRadius: 2,
      },
    }),
    buttonDisabled: Platform.select({
      ios: {},
      android: {
        borderRadius: 2,
        elevation: 0,
        backgroundColor: "#dfdfdf",
      },
      web: {
        borderRadius: 2,
        backgroundColor: "#dfdfdf",
      },
    }),
    text: Platform.select({
      ios: {
        // iOS blue from https://developer.apple.com/ios/human-interface-guidelines/visual-design/color/
        color: "#007AFF",
        textAlign: "center",
        padding: 8,
        fontSize: 18,
      },
      android: {
        color: "white",
        textAlign: "center",
        padding: 8,
        fontWeight: "500",
      },
      web: {
        color: "white",
        textAlign: "center",
        padding: 8,
        fontWeight: "500",
      },
    }),
    textDisabled: Platform.select({
      ios: {
        textAlign: "center",
        padding: 8,
        fontSize: 18,
        color: "#cdcdcd",
      },
      android: {
        textAlign: "center",
        padding: 8,
        fontWeight: "500",
        color: "#a1a1a1",
      },
      web: {
        textAlign: "center",
        padding: 8,
        fontWeight: "500",
        color: "#a1a1a1",
      },
    }),
    link: {},
    tabBarButton: {},
    drawerButton: {},
    tab: {
      display: "flex",
      height: "100%",
      padding: 24, //theme.spacing.unit * 3
    },
    paragraph: { fontSize: 17 }, // fontFamily: 'SF Pro Text',},
    title: { fontSize: 34, fontWeight: "bold" }, // fontFamily: 'SF Pro Display', fontStyle: 'bold',},
    subtitle: { fontSize: 17, fontWeight: "bold" }, // fontFamily: 'SF Pro Text', fontStyle: 'bold',},
    itemtitle: { fontSize: 17, fontWeight: "bold" }, // fontFamily: 'SF Pro Text', fontStyle: 'bold',},
    itemsubtitle: { fontSize: 15, fontStyle: "italic" }, // fontFamily: 'SF Pro Text', fontStyle: 'bold',},
    itemtext: { fontSize: 15 }, // fontFamily: 'SF Pro Text',},

    modal: {
      background: {
        backgroundColor: "rgba(25,25,25,0.4)", //'#444', //lemonchiffon', //rgba(255, 250, 205, 0.8)', //lemonchiffon
        position: "absolute",
        zIndex: 10000,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      },
      message: {
        backgroundColor: "rgba(25,25,25,0.9)", //'#444', //lemonchiffon', //rgba(255, 250, 205, 0.8)', //lemonchiffon
        borderColor: "#EEE", //'darkred',
        borderWidth: 1,
        padding: 24,
        position: "absolute",
        zIndex: 10001,
        left: "20%",
        top: "20%",
        width: "60%",
        shadowOffset: { width: 10, height: 10 },
        shadowColor: "#000",
        shadowRadius: 10,
        shadowOpacity: 0.5,
        elevation: 2,
      },
      text: {
        fontSize: 14,
        color: "#EEE", //'#8B0000', //'DarkRed',
      },
      title: {
        fontSize: 18,
        color: "#EEE", //'#8B0000', //'DarkRed',
      },
    },
  },
};

let themeList = {};

const createTheme = (name, theme) => {
  return merge({}, themeBlueprint, theme, { name: name });
};

themeList[themeActions.THEMES.SPORTMONITOR] = createTheme("Sportmonitor", {
  style: {
    tab: {
      backgroundImage:
        "linear-gradient(to bottom, #e5f3ff 0%, rgba(255, 255, 255, 1) 50%)",
    },
  },
  key: themeActions.THEMES.SPORTMONITOR,
});
themeList[themeActions.THEMES.DARK] = createTheme("Dark", {
  colors: {
    primaryColor: black,
    primaryContrast: white,
    secondaryColor: sportmonitorOrange,
    secondaryContrast: white,
    gradientTop: "#eee",
    gradientBottom: "rgba(255, 255, 255, 1)",
  },
  style: {
    Text:
      Platform.OS === "ios"
        ? {
            paragraph: { fontSize: 17 },
            text: { fontSize: 17 },
            title: { fontSize: 34, fontWeight: "bold" },
            subtitle: { fontSize: 17, fontWeight: "bold" },
            itemtitle: { fontSize: 17, fontWeight: "bold" },
            itemsubtitle: { fontSize: 15, fontStyle: "italic" },
            itemtext: { fontSize: 15 },
          }
        : {
            paragraph: { fontSize: 17, fontFamily: "Roboto" },
            text: { fontSize: 17, fontFamily: "Roboto" },
            title: { fontSize: 34, fontFamily: "Roboto", fontWeight: "bold" },
            subtitle: {
              fontSize: 17,
              fontFamily: "Roboto",
              fontWeight: "bold",
            },
            itemtitle: {
              fontSize: 17,
              fontFamily: "Roboto",
              fontWeight: "bold",
            },
            itemsubtitle: {
              fontSize: 15,
              fontFamily: "Roboto",
              fontStyle: "italic",
            },
            itemtext: { fontSize: 15, fontFamily: "Roboto" },
          },
  },
  key: themeActions.THEMES.DARK,
});

const themes = themeList;
const defaultTheme = { key: themeActions.THEMES.SPORTMONITOR };

export default function theme(): themeType {
  const themeKey = ((reduxStore.getState() as unknown) as RootState).theme.key;
  return themeList[themeKey] || themeList[defaultTheme.key];
}

export { themes, defaultTheme };
