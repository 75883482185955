import datastore from "../datasource";

export const SET_INVITES = "SET_INVITES";

function fetchUserFromServer() {
  return fetch(datastore.get() /*'/static_api/static_api_data.json'*/);
}

export function initInvites() {
  return (dispatch) => {
    fetchUserFromServer()
      .then(
        (response) => {
          if (response.ok) return response.json();
          else throw new Error(JSON.stringify(response));
        },
        (response) => {
          alert(JSON.stringify(response));
          throw new Error("HTTP error " + response.status);
        }
      )
      .then((json) => {
        dispatch(setInvites(json.invites));
      })
      .catch((err) => {
        alert(err);
      });
  };
}

export function setInvites(invites) {
  return { type: SET_INVITES, invites };
}
