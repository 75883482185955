let datastore = {
  datasource: { uri: "/static_api/static_api_data.json", options: {} },
  get: function () {
    return this.datasource;
  },
  set: function (new_source) {
    this.datasource = new_source;
  },
};

export default datastore;
