import { organizationActions, roleActions } from "../actions";
import * as initial_state_json from "../initial_state.json";

export default function organizations(
  state = initial_state_json.organizations || {},
  action
) {
  switch (action.type) {
    case roleActions.RESET_ROLES:
      return Object.assign({}, action.json.organizations);
    case organizationActions.SET_ORGANIZATION:
      return Object.assign({}, state, action.organizations);
    default:
      return state;
  }
}
