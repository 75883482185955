import { combineReducers } from "redux";
import drawer from "./reducers/drawer";
import user from "./reducers/user";
import roles from "./reducers/role";
import teams from "./reducers/team";
import players from "./reducers/player";
import respondents from "./reducers/respondent";
import reports from "./reducers/report";
import answers from "./reducers/answer";
import invites from "./reducers/invite";
import userforms from "./reducers/userform";
import standardforms from "./reducers/standardform";
import organizations from "./reducers/organization";
import session from "./reducers/session";
import { reducer as network } from "../proxy/react-native-offline/src"; //"react-native-offline";
import theme from "./reducers/theme";
import { RESET_STATE, LOG_OUT } from "./actions";

const reducers = combineReducers({
  drawer,
  user,
  session,
  network,
  theme,
  roles,
  teams,
  players,
  respondents,
  invites,
  userforms,
  standardforms,
  reports,
  answers,
  organizations,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    state = action.state;
  }
  if (action.type === LOG_OUT) {
    state = action.state;
  }
  return reducers(state, action);
};

export default rootReducer;
