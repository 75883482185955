import React from "react";

import { connect } from "react-redux";
import { Text, View } from "react-native";
import { RootState } from "../redux/types";
import Constants from "expo-constants";

class LoginInfo extends React.Component<
  { organization; currentRole; isConnected; textStyle },
  any
> {
  render() {
    // translation
    // const {t} = this.props;

    // map props holding redux state to shorter names
    // const { data } = this.props; // this.props.data
    const { organization, currentRole, textStyle, isConnected } = this.props;
    return (
      <View>
        <Text style={textStyle}>
          {isConnected ? "Online " : "Offline "}
          {Constants.manifest.version.toString()}
        </Text>
        <Text style={textStyle}>{currentRole && currentRole.displayname}</Text>
        <Text style={textStyle}>{currentRole && currentRole.rolename}</Text>
        <Text style={textStyle}>
          {organization && organization.displayname}
        </Text>
      </View>
    );
  }
  componentDidMount() {}
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    currentRole: state.roles[state.user.currentId],
    organization:
      state.organizations[state.roles[state.user.currentId].organization],
    isConnected: state.network.isConnected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginInfo);
