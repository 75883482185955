//import datastore from "../datasource";
//import { userActions } from "../actions";
import * as server from "../server/server";

export const GET_ROLES = "GET_ROLES";
export const RESET_ROLES = "RESET_ROLES";
export const SET_ACTIVE_ROLE = "SET_ACTIVE_ROLE";

export function get_roles() {
  server.resourceGet(server.apiResource.role, null, null, {
    onSuccess: { action: RESET_ROLES, params: {} },
  });
}
