import { RESET_ROLES, themeActions } from "../actions";
import { themes, defaultTheme } from "../../theme";

export default function theme(state = defaultTheme, action) {
  switch (action.type) {
    case themeActions.SET_THEME:
      const newThemeObject = themes[action.theme];
      return Object.assign({}, state, newThemeObject);
    default:
      return state;
  }
}
