import { standardformActions, roleActions } from "../actions";
import * as initial_state_json from "../initial_state.json";

const initial_state = initial_state_json.standardforms;
export default function standardform(state = initial_state || {}, action) {
  switch (action.type) {
    case roleActions.RESET_ROLES:
      return Object.assign({}, action.json.standardforms);
    case standardformActions.SET_STANDARDFORMS:
      return Object.assign({}, state, action.standardforms);
    default:
      return state;
  }
}
