import { playerActions, roleActions } from "../actions";
import * as initial_state_json from "../initial_state.json";

const initialstate = initial_state_json.players || {};

export default function player(state = initialstate, action) {
  switch (action.type) {
    case roleActions.RESET_ROLES: {
      return action.json.players;
    }

    case playerActions.ADD_PLAYER: {
      let player = {};
      player[action.player.id] = action.player;
      return { ...state, ...player };
    }

    case playerActions.UPDATE_PLAYER: {
      let player = {};
      player[action.player.id] = action.player;
      return { ...state, ...player };
    }

    case playerActions.REMOVE_PLAYER: {
      let players = Object.assign({}, state);
      delete players[action.player.id];
      return players;
    }

    default:
      return state;
  }
}
