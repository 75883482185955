import { answerActions, roleActions } from "../actions";
import * as initial_state_json from "../initial_state.json";

export default function answer(
  state = initial_state_json.answers || {},
  action
) {
  switch (action.type) {
    case roleActions.RESET_ROLES:
      return Object.assign({}, action.json.answers);
    case answerActions.SET_ANSWERS:
      return Object.assign({}, state, action.answers);
    default:
      return state;
  }
}
