import React, { Suspense, useRef } from "react";
//import './template/sportmonitor.css';
import { AppState, AppStateStatus, Text, View } from "react-native";

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { navigationRef } from "./components/RootNavigation";
// import { DefaultTheme, DarkTheme } from "@react-navigation/native";
// import { createDrawerNavigator } from '@react-navigation/drawer';

import * as Localization from "expo-localization";
import i18n from "i18n-js";

import { AppLoading } from "expo";
import { Asset } from "expo-asset";
import Constants from "expo-constants";
// import * as Font from "expo-font";
import "@expo/match-media";
import ReduxStore from "./redux/store";
import PersistLoadingScreen from "./screens/PersistLoading";
import {
  IconInvite,
  IconTeam,
  //  IconPlayer,
  IconRespondent,
  IconReport,
  IconMonitor,
  IconFeedback,
  IconAccount,
} from "./icons/iconset";

// import { Icon } from 'react-native-elements'

import * as translations from "./localization/translations.json";
// import { Icon } from "expo";

import HeaderRight from "./components/HeaderRight";

//link FontAwesome Icons
import { ImportFontawesomeIcons } from "./components/FontAwesome/FontAwesomeLibrary";
import { Updates } from "expo/build/removed.web";

const FormDetailScreen = React.lazy(() => import("./screens/FormDetailScreen"));
const LoginScreen = React.lazy(() => import("./screens/Login"));
//const SignInScreen = React.lazy(() => import("./screens/SignInScreen"));
const SignUpScreen = React.lazy(() => import("./screens/SignUpScreen"));
const ResetPasswordScreen = React.lazy(
  () => import("./screens/ResetPasswordScreen")
);
//const HomeScreen = React.lazy(() => import("./screens/HomeScreen"));
const HomeScreenRedux = React.lazy(() => import("./screens/HomeScreenRedux"));
const TeamScreen = React.lazy(() => import("./screens/TeamScreen"));
const InviteScreen = React.lazy(() => import("./screens/InviteScreen"));
const FormScreen = React.lazy(() => import("./screens/FormScreen"));
const ReportListScreen = React.lazy(() => import("./screens/ReportListScreen"));
// const InviteListScreen = React.lazy(() => import("./screens/InviteListScreen"));
const ProfileScreen = React.lazy(() => import("./screens/ProfileScreen"));
const AccountScreen = React.lazy(() => import("./screens/AccountScreen"));
const RespondentScreen = React.lazy(() => import("./screens/RespondentScreen"));
const LogoWhite = React.lazy(() => import("./components/LogoWhite"));
ImportFontawesomeIcons();

/*
// To create own react-navigation theme modify this object

const DarkTheme: Theme = {
  dark: true,
  colors: {
    primary: 'rgb(10, 132, 255)',
    background: 'rgb(1, 1, 1)',
    card: 'rgb(18, 18, 18)',
    text: 'rgb(229, 229, 231)',
    border: 'rgb(39, 39, 41)',
    notification: "rgb(255, 45, 85)",
  },
};

const DefaultTheme: Theme = {
  dark: false,
  colors: {
    primary: 'rgb(0, 122, 255)',
    background: 'rgb(242, 242, 242)',
    card: 'rgb(255, 255, 255)',
    text: 'rgb(28, 28, 30)',
    border: 'rgb(199, 199, 204)',
    notification: "rgb(255, 45, 85)",
  },
};
*/
const MyTheme = {
  dark: false,
  colors: {
    primary: "rgb(255, 45, 85)",
    background: "rgb(244, 242, 242)", // screen background
    card: "rgb(48, 64, 128)", // header and tabbar
    text: "rgb(255, 255, 255)",
    border: "rgb(199, 199, 204)",
    notification: "rgb(255, 45, 85)",
  },
};

i18n.translations = {
  en: translations.en,
  nl: translations.nl,
};
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
// When a value is missing from a language it'll fallback to another language with the key present.
i18n.fallbacks = true;

/* TODO
// The language setting may have changed when the app was in background.
// On Android this doesn't cause the app to reset, so we must check somewhere
// See: https://docs.expo.io/versions/v36.0.0/sdk/localization/

// When the app returns from the background on Android...
const { locale } = await Localization.getLocalizationAsync();

*/

enum MenuType {
  Tab = 1,
  Drawer = 2,
}
export interface NavigatorState {
  testScreen: boolean;
  menuType: MenuType;
  menu: {
    invite: boolean;
    team: boolean;
    respondent: boolean;
    compose: boolean;
    form: boolean;
    report: boolean;
    profile: boolean;
    account: boolean;
  };
}
export interface SportmonitorAppState {
  appState: AppStateStatus;
  userToken: string;
  isSignout: boolean;
  userType: number;
  session: {
    isLoadingComplete: boolean;
  };
}

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();
//const Drawer = createDrawerNavigator();

function LoginStack(props) {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Login"
        component={LoginScreen}
        options={{
          title: "Sign in",
          // When logging out, a pop animation feels intuitive
          // You can remove this if you want the default 'push' animation
          animationTypeForReplace: "pop",
        }}
      />
      <Stack.Screen name="SignUp" component={SignUpScreen} />
      <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />
    </Stack.Navigator>
  );
}

function AppTabs(props) {
  let menu = props.menu;
  return (
    <Tab.Navigator
      tabBarOptions={{
        activeTintColor: "tomato",
        inactiveTintColor: "white",
        labelStyle: {
          fontSize: 12,
        },
      }}
    >
      {menu.invite ? (
        <Tab.Screen
          name="invitescreen"
          component={InviteScreen}
          options={{
            title: i18n.t("tab.invites"),
            tabBarLabel: i18n.t("tab.invites"),
            tabBarIcon: ({ focused, color, size }) => (
              <IconInvite focused={focused} color={color} size={size} />
            ),
          }}
        />
      ) : null}
      {menu.team ? (
        <Tab.Screen
          name="teamscreen"
          component={TeamScreen}
          options={{
            title: i18n.t("tab.teams"),
            tabBarLabel: i18n.t("tab.teams"),
            tabBarIcon: ({ focused, color, size }) => (
              <IconTeam focused={focused} color={color} size={size} />
            ),
          }}
        />
      ) : null}
      {menu.respondent ? (
        <Tab.Screen
          name="respondentscreen"
          component={RespondentScreen}
          options={{
            title: i18n.t("tab.respondents"),
            tabBarLabel: i18n.t("tab.respondents"),
            tabBarIcon: ({ focused, color, size }) => (
              <IconRespondent focused={focused} color={color} size={size} />
            ),
          }}
        />
      ) : null}
      {menu.compose ? (
        <Tab.Screen
          name="monitorscreen"
          component={HomeScreenRedux}
          options={{
            title: i18n.t("tab.monitor"),
            tabBarLabel: i18n.t("tab.monitor"),
            tabBarIcon: ({ focused, color, size }) => (
              <IconMonitor focused={focused} color={color} size={size} />
            ),
          }}
        />
      ) : null}
      {menu.form ? (
        <Tab.Screen
          name="formscreen"
          component={FormScreen}
          options={{
            title: i18n.t("tab.forms"),
            tabBarLabel: i18n.t("tab.forms"),
            tabBarIcon: ({ focused, color, size }) => (
              <IconFeedback focused={focused} color={color} size={size} />
            ),
          }}
        />
      ) : null}
      {menu.report ? (
        <Tab.Screen
          name="reportlistscreen"
          component={ReportListScreen}
          options={{
            title: i18n.t("tab.reports"),
            tabBarLabel: i18n.t("tab.reports"),
            tabBarIcon: ({ focused, color, size }) => (
              <IconReport focused={focused} color={color} size={size} />
            ),
          }}
        />
      ) : null}
      {menu.profile ? (
        <Tab.Screen
          name="profilescreen"
          component={ProfileScreen}
          options={{
            title: i18n.t("tab.profile"),
            tabBarLabel: i18n.t("tab.profile"),
            tabBarIcon: ({ focused, color, size }) => (
              <IconRespondent focused={focused} color={color} size={size} />
            ),
          }}
        />
      ) : null}
      {menu.account ? (
        <Tab.Screen
          name="accountscreen"
          component={AccountScreen}
          options={{
            title: i18n.t("tab.account"),
            tabBarLabel: i18n.t("tab.account"),
            tabBarIcon: ({ focused, color, size }) => (
              <IconAccount focused={focused} color={color} size={size} />
            ),
          }}
        />
      ) : null}
    </Tab.Navigator>
  );
}
/*
            {menu.invite ? <Tab.Screen name="invitescreen" component={InviteScreen} options={{ tabBarLabel: i18n.t('tab.invites'),  tabBarIcon : ({color, size})=>(<Icon name='sc-telegram' type='evilicon' color='#517fa4' />) }} /> : null}
            {menu.team ? <Tab.Screen name="teamscreen" component={TeamScreen} options={{ tabBarLabel: i18n.t('tab.teams'), tabBarIcon : ({focused, color, size})=>(<Icon name='sc-telegram' type='evilicon' color='#517fa4' />) }} /> : null}
            {menu.respondent ? <Tab.Screen name="respondentscreen" component={RespondentScreen} options={{ tabBarLabel: i18n.t('tab.respondents'), tabBarIcon : ({focused, color, size})=>(<Icon name='sc-telegram' type='evilicon' color='#517fa4' />) }} /> : null}
            {menu.compose ? <Tab.Screen name="monitorscreen" component={HomeScreen} options={{ title: i18n.t('tab.monitor'), tabBarIcon : ({focused, color, size})=>(<Icon name='sc-telegram' type='evilicon' color='#517fa4' />) }} /> : null}
            {menu.form ? <Tab.Screen name="formscreen" component={FormScreen} options={{ tabBarLabel: i18n.t('tab.userforms'), tabBarIcon : ({focused, color, size})=>(<Icon name='sc-telegram' type='evilicon' color='#517fa4' />) }} /> : null}
            {menu.report ? <Tab.Screen name="reportscreen" component={ReportScreen} options={{ tabBarLabel: i18n.t('tab.reports'), tabBarIcon : ({focused, color, size})=>(<Icon name='sc-telegram' type='evilicon' color='#517fa4' />) }} /> : null}
            {menu.profile ? <Tab.Screen name="profilescreen" component={ProfileScreen} options={{ tabBarLabel: i18n.t('tab.profile'), tabBarIcon : ({focused, color, size})=>(<Icon name='sc-telegram' type='evilicon' color='#517fa4' />) }} /> : null}
            {menu.account ? <Tab.Screen name="accountscreen" component={AccountScreen} options={{ tabBarLabel: i18n.t('tab.account'), tabBarIcon : ({focused, color, size})=>(<Icon name='sc-telegram' type='evilicon' color='#517fa4' />) }} /> : null}
*/

/*
function AppDrawer(props) {
    let menu = props.menu;
    return (
        <Drawer.Navigator>
            {menu.invite ? <Drawer.Screen name='invitescreen' options={{ title: i18n.t('Invites')}} component={InviteScreen}/> : null}
            {menu.team ? <Drawer.Screen name='teamscreen' options={{ title: i18n.t('Teams')}} component={TeamScreen}/> : null}
            {menu.respondent ? <Drawer.Screen name='respondentscreen' options={{ title: i18n.t('Respondents')}} component={RespondentScreen}/> : null}
            {menu.compose ? <Drawer.Screen name='monitorscreen' options={{ title: i18n.t('Monitor')}} component={HomeScreen}/> : null}
            {menu.form ? <Drawer.Screen name='formscreen' options={{ title: i18n.t('Forms')}} component={FormScreen}/> : null}
            {menu.report ? <Drawer.Screen name='reportscreen' options={{ title: i18n.t('Reports')}} component={ReportScreen}/> : null}
            {menu.profile ? <Drawer.Screen name='profilescreen' options={{ title: i18n.t('Profile')}} component={ProfileScreen}/> : null}
            {menu.account ? <Drawer.Screen name='accountscreen' options={{ title:  i18n.t('Account')}} component={AccountScreen}/> : null}
        </Drawer.Navigator>
    );
}
*/

function AppNavigator() {
  let state = {
    appState: AppState.currentState,
    userToken: "token",
    isSignout: false,
    session: {
      isLoadingComplete: false,
    },
    testScreen: false,
    userType: 1,
    menuType: MenuType.Tab,
    menu: {
      invite: true,
      team: true,
      respondent: false,
      compose: true,
      form: true,
      report: false,
      profile: false,
      account: false,
    },
  };
  if (state.userToken == null) {
    // No token found, user isn't signed in
    return LoginStack({});
  } else {
    // User is signed in
    //       if (state.menuType == MenuType.Tab){
    return AppTabs({ menu: state.menu, menuType: state.menuType });
    //       } else {
    //           return AppDrawer({menu:state.menu, menuType: state.menuType});
    //       }
  }
}

//let HeaderLogo = (allowFontScaling, style, children) => <LogoWhite />;
let HeaderRightComponent = () => <HeaderRight />;
export default class App extends React.Component<any, SportmonitorAppState> {
  destructor() {}

  constructor(props) {
    super(props);
    //        this.handleChange = this.handleChange.bind(this);
    this.state = {
      appState: AppState.currentState,
      userToken: "token",
      isSignout: false,
      session: {
        isLoadingComplete: true,
      },
      userType: 1,
    };
  }

  render() {
    if (!this.state.session.isLoadingComplete)
      return (
        <AppLoading
          startAsync={this._loadResourcesAsync}
          onError={this._handleLoadingError}
          onFinish={this._handleFinishLoading}
        />
      );
    //       screenOptions={{ headerRight:HeaderRight }}
    return (
      <ReduxStore>
        <Suspense
          fallback={
            <View>
              <Text>Loading</Text>
            </View>
          }
        >
          <NavigationContainer ref={navigationRef} theme={MyTheme}>
            <Stack.Navigator
              initialRouteName="root"
              screenOptions={{ headerRight: HeaderRightComponent }}
            >
              <Stack.Screen name="loading" component={PersistLoadingScreen} />
              <Stack.Screen
                name="root"
                component={AppNavigator}
                options={{
                  headerTitle: () => <LogoWhite />,
                  title: "Sportmonitor",
                }}
              />
              <Stack.Screen name="FormDetails" component={FormDetailScreen} />
              <Stack.Screen
                name="LoginScreen"
                options={{ headerTitle: "Aanmelden als" }}
                component={LoginScreen}
              />
            </Stack.Navigator>
          </NavigationContainer>
        </Suspense>
      </ReduxStore>
    );
  }
  /*
  componentDidMount() {
    AppState.addEventListener("change", this._handleAppStateChange);
  }

  componentWillUnmount() {
    AppState.removeEventListener("change", this._handleAppStateChange);
  }

  _handleAppStateChange = (nextAppState) => {
    if (
      this.state.appState.match(/inactive|background/) &&
      nextAppState === "active"
    ) {
      console.log("App has come to the foreground!");

      try {
        const update = await Updates.checkForUpdateAsync();
        if (update.isAvailable) {
          await Updates.fetchUpdateAsync();
          // ... notify user of update ...
          await Updates.reloadAsync();
        }
      } catch (e) {
        // handle or log error
      }

    if (
      nextAppState.match(/inactive|background/) &&
      this.state.appState === "active"
    ) {
      console.log("App has gone to the background!");
    }

    this.setState({ appState: nextAppState });
  };
*/
  _loadResourcesAsync: any = async () => {
    return Promise.all([
      Asset.loadAsync([
        require("./assets/images/icon.png"), // app icon
        require("./assets/logo/logo_header.svg"), // header logo
        // menu icons
        require("./assets/app_icons/Account.png"),
        require("./assets/app_icons/Feedback.png"),
      ]),
      //     Font.loadAsync({
      // This is the font that we are using for our tab bar
      // ...Icon.Ionicons.font,
      // sportmonitor: require("./assets/fonts/sportmonitor.ttf"),
      //              'Roboto': require('./assets/fonts/Roboto/Roboto-Regular.ttf')
      //     }),
    ]);
  };

  _handleLoadingError = (error) => {
    // In this case, you might want to report the error to your error
    // reporting service, for example Sentry
    console.warn(error);
  };

  _handleFinishLoading = () => {
    this.setState({ session: { isLoadingComplete: true } });
  };
}
