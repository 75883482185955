import { inviteActions, roleActions } from "../actions";
import * as initial_state_json from "../initial_state.json";

export default function invites(
  state = initial_state_json.invites || {},
  action
) {
  switch (action.type) {
    case roleActions.RESET_ROLES:
      return Object.assign({}, action.json.invites);
    case inviteActions.SET_INVITES:
      return Object.assign({}, state, action.invites);
    default:
      return state;
  }
}
