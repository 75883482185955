var prefix = "_s";
var _0 = {
  prefix: "_s",
  iconName: "0",
  icon: [
    435,
    870,
    [],
    "0031",
    "M 95,1.9999705 0.511719,96.48826 v 680.3144 L 95,871.29096 h 245.66992 l 94.48828,-94.4883 V 96.48826 L 340.66992,1.9999705 Z M 151.19282,172.57853 h 132.28347 l 18.89764,18.89764 V 701.7124 l -18.89764,18.89764 H 151.19282 L 132.29519,701.7124 V 191.47617 Z",
  ],
};
var _1 = {
  prefix: "_s",
  iconName: "1",
  icon: [
    227,
    870,
    [],
    "0031",
    "m 95.00001,1.999975 -94.48738,94.48805 0.002,94.488475 94.4865,-2.9e-4 -0.002,680.31497 H 227.28428 L 227.28347,1.999975 Z",
  ],
};
var _2 = {
  prefix: "_s",
  iconName: "2",
  icon: [
    435,
    870,
    [],
    "0031",
    "M 94.988281,0.5 0.5,94.988281 V 246.16992 h 132.2832 l 3.6e-4,-75.5915 18.89764,-18.89764 h 132.28346 l 18.89764,18.89764 V 340.65716 L 283.96466,359.5548 H 132.78249 L 0.5000916,491.83826 c -6.505e-5,125.98426 -1.2213e-4,251.96851 0,377.95277 113.3858284,-10e-6 321.2598484,-10e-6 434.6456784,0 V 718.60992 l -302.36257,-5.4e-4 3.6e-4,-207.87348 18.89764,-18.89764 H 302.86142 L 435.14577,359.5548 435.14648,94.988281 340.6582,0.5 Z",
  ],
};
var _3 = {
  prefix: "_s",
  iconName: "3",
  icon: [
    435,
    870,
    [],
    "0031",
    "M 94.988281,0.5 0.5,94.988281 V 246.16992 h 132.2832 l 3.6e-4,-75.5915 18.89764,-18.89764 h 132.28346 l 18.89764,18.89764 v 132.28346 l -18.89764,18.89764 h -94.48819 l -18.89764,18.89764 v 188.97638 l 18.89764,18.89764 h 94.48819 l 18.89764,18.89764 v 132.28346 l -18.89764,18.89764 H 151.6812 l -18.89764,-18.89764 -3.6e-4,-75.59119 H 0.5 v 151.18164 l 94.488281,94.48829 H 340.6582 l 94.48828,-94.48829 V 529.63281 L 340.6582,435.14453 435.14648,340.65625 V 94.988281 L 340.6582,0.5 Z",
  ],
};
var _4 = {
  prefix: "_s",
  iconName: "4",
  icon: [
    435,
    870,
    [],
    "0031",
    "M 188.75197,-0.1217483 -0.22441593,491.21683 V 604.60266 L 18.673222,623.5003 H 245.44488 l -9.7e-4,245.66929 132.28444,10e-6 7.1e-4,-245.67003 37.79456,7.3e-4 18.89764,-18.89764 v -94.48819 l -18.89764,-18.89763 -37.79456,-9.1e-4 V -0.121424 Z M 245.44488,188.85463 v 302.3622 H 132.05905 Z",
  ],
};
var _5 = {
  prefix: "_s",
  iconName: "5",
  icon: [
    435,
    870,
    [],
    "0031",
    "M 94.988281,0.5 0.5,94.988281 0.50096649,359.5548 132.78249,491.83826 h 151.18217 l 18.89764,18.89764 v 188.97638 l -18.89764,18.89764 H 151.6812 l -18.89764,-18.89764 -3.6e-4,-75.59119 H 0.5 v 151.18164 l 94.488281,94.48829 H 340.6582 L 435.14648,775.30273 435.14577,491.83826 302.8623,359.5548 H 151.6812 L 132.78356,340.65716 V 170.57842 l 20.07358,-18.89678 c 94.09645,0 188.19289,0 282.28934,0 L 435.14577,0.49967568 Z",
  ],
};
var _6 = {
  prefix: "_s",
  iconName: "6",
  icon: [
    435,
    870,
    [],
    "0031",
    "M 94.988281,0.5 0.5,94.988281 V 775.30273 l 94.488281,94.48829 H 340.6582 l 94.48828,-94.48829 -6.2e-4,-321.25966 -94.48828,-94.48828 -188.97638,10e-6 -18.89764,-18.89764 V 170.57842 l 18.89764,-18.89764 h 132.28346 l 18.89764,18.89764 v 37.79526 H 435.14745 L 435.14648,94.988281 340.6582,0.5 Z M 151.6812,529.63354 h 132.28346 l 18.89764,18.89764 v 151.1811 l -18.89764,18.89764 H 151.6812 l -18.8979,-19.74675 2.6e-4,-150.33199 z",
  ],
};
var _7 = {
  prefix: "_s",
  iconName: "7",
  icon: [
    435,
    870,
    [],
    "0031",
    "M -2.9802322e-8,0.99981523 0.5,265.56641 H 132.2832 V 171.07812 H 302.36133 L 170.07874,870.29116 H 302.36221 L 435.14648,94.988281 340.6582,0.5 Z",
  ],
};
var _8 = {
  prefix: "_s",
  iconName: "8",
  icon: [
    115,
    230,
    [],
    "0031",
    "m 0.1322222,140.13215 0,65 24.9999998,25 h 65 l 24.999998,-25 0,-65 -19.999998,-25 19.999998,-24.999995 0,-65 L 90.132222,0.13215458 h -65 L 0.1322222,25.132155 v 65 L 20.132222,115.13215 m 15,30 5,-5 h 35 l 5,5 v 40 l -5,5 h -35 l -5,-5 m 5,-144.999995 h 35 l 5,5 0,40 -5,5 h -35 l -5,-5 0,-40 z",
    //"m113 215-94 94 9.1e-5 246 94 94-94 94-9.1e-5 246 94 94 246-4e-4 94-94v-246l-94-94 94-94v-246l-94-94zm57 151h132l19 19v151l-19 19h-132l-19-19v-151zm151 397v151l-19 19h-132l-19-19v-151l19-19h132z",
  ],
};
var _9 = {
  prefix: "_s",
  iconName: "9",
  icon: [
    435,
    870,
    [],
    "0031",
    "M 94.999999,-3.0000783 0.5117158,91.4882 l 7.6e-5,321.25914 94.4882872,94.48828 H 283.97549 l 18.89764,18.89764 V 696.212 l -18.89764,18.89764 H 151.69202 L 132.79438,696.212 V 620.62145 H 0.5111798 l 5.36e-4,151.18121 94.4882832,94.48828 H 340.66797 l 94.48828,-94.48828 V 526.13273 l 3.4e-4,-94.48766 -3.4e-4,-94.4889 V 91.4882 L 340.66797,-3.0000783 Z M 151.69202,148.1805 h 132.28347 l 18.89764,18.89764 v 151.1811 l -18.89764,18.89764 H 151.69202 l -18.89764,-18.89764 v -151.1811 z",
    //"m114 38-94 94 7.6e-5 321 94 94h189l19 19v170l-19 19h-132l-19-19v-76h-132l5.4e-4 151 94 94h246l94-94v-246l3.4e-4 -94-3.4e-4 -94v-246l-94-94zm57 151h132l19 19v151l-19 19h-132l-19-19v-151z",
  ],
};

var _iconsCache = {
  _0: _0,
  _1: _1,
  _2: _2,
  _3: _3,
  _4: _4,
  _5: _5,
  _6: _6,
  _7: _7,
  _8: _8,
  _9: _9,
};
export { _iconsCache as _s, prefix, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9 };
