import { roleActions } from "../actions";
import * as initial_state_json from "../initial_state.json";

export default function roles(state = initial_state_json.roles || {}, action) {
  switch (action.type) {
    case roleActions.RESET_ROLES:
      return Object.assign({}, action.json.roles);
    /*
    case teamActions.FETCH_TEAMS_SUCCESS: {
      let newstate = Object.assign({}, state);
      for (const [key, value] of Object.entries(action.json.roles)) {
        // console.log(`${key}: ${value}`);
        newstate.roles[key].teams = value.teams;
      }
      return newstate;
    }
*/
    default:
      return state;
  }
  return state;
}
