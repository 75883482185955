import { sessionActions, userActions } from "../actions";

export default function user(state = [], action) {
  switch (action.type) {
    case userActions.AUTHENTICATED_SESSION_PRE_START:
      return Object.assign({}, state, {
        username: action.username,
        uuid: action.uuid,
        code: action.code,
      });
    case sessionActions.SET_SESSION:
      return action.session;
    default:
      return state;
  }
}
