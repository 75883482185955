import React from "react";
import Svg, { Path } from "react-native-svg";
import { View, StyleSheet, Text } from "react-native";

export default class SvgLogo extends React.Component {
  render() {
    return (
      /*            <View style={[ StyleSheet.absoluteFill, { alignItems: 'center', justifyContent: 'center'}, ]}>
                <Text>Logo</Text>*/
      <Svg width={222} height={33} viewBox="0 0 222.28 33.63">
        <Path
          fill="white"
          d="M52.83,16.11a4.5,4.5,0,0,0-3.1-1c-1.32,0-2.08.49-2.19,1.18s.49,1.21,1.83,1.23c2.71.09,5.89.66,5.24,4.56-.37,2.3-2.48,4.76-6.88,4.76a7.91,7.91,0,0,1-6.09-2.41l2.24-2.93a6.23,6.23,0,0,0,4.52,1.78c1.17,0,2.18-.46,2.28-1.21s-.27-1.2-2.14-1.26c-2.56-.11-5.6-1.2-5-4.53s3.76-4.56,6.76-4.56a6.45,6.45,0,0,1,5,1.84Z"
        />
        <Path
          fill="white"
          d="M55.22,32.53l3.52-20.4h3.62l-.06,1.81a6.2,6.2,0,0,1,5-2.07c4.07,0,6.34,2.93,5.58,7.35-.79,4.59-4.1,7.37-8.17,7.37a4.54,4.54,0,0,1-4-2l-1.37,7.95ZM68.76,19.21c.35-2-.61-3.64-2.77-3.64a4.19,4.19,0,0,0-4.36,3.64,3,3,0,0,0,3.1,3.64A4.12,4.12,0,0,0,68.76,19.21Z"
        />
        <Path
          fill="white"
          d="M74.65,19.21a8.81,8.81,0,0,1,8.85-7.43c4.63,0,7.06,3.16,6.32,7.43a8.7,8.7,0,0,1-8.87,7.37C76.26,26.59,73.91,23.49,74.65,19.21Zm11.07,0c.31-1.78-.42-3.73-2.86-3.73a4.28,4.28,0,0,0-4.12,3.73c-.31,1.78.4,3.7,2.84,3.7A4.17,4.17,0,0,0,85.72,19.21Z"
        />
        <Path
          fill="white"
          d="M97.4,12.13l0,1.55a5.1,5.1,0,0,1,4-1.89,4.06,4.06,0,0,1,3.17,1.29l-2.25,3.27a3.1,3.1,0,0,0-2.09-.72,3.8,3.8,0,0,0-3.79,3.27l-1.27,7.37h-4l2.44-14.15Z"
        />
        <Path
          fill="white"
          d="M112.55,8.22l-.68,4h3.51l-.59,3.44h-3.53L110.32,21c-.26,1.49.49,1.89,1.24,1.89a4.74,4.74,0,0,0,1.74-.34l.33,3.44a9.38,9.38,0,0,1-3,.63c-3,.14-5.06-1.21-4.3-5.62l.92-5.34h-2.47l.59-3.44h2.47l.6-3.5Z"
        />
        <Path
          fill="white"
          d="M118.55,12.13h2.13l-.22,2.07A5.38,5.38,0,0,1,125,11.87c1.91,0,3.73.83,4.12,3.1a6.33,6.33,0,0,1,5.41-3.1c3.65,0,5.41,2.44,4.75,6.31l-1.4,8.09h-2.3l1.39-8.09c.4-2.32-.61-4.1-2.88-4.1a4.76,4.76,0,0,0-4.5,4.16l-1.39,8h-2.3l1.39-8c.4-2.32-.71-4.16-3-4.16a4.74,4.74,0,0,0-4.47,4.16l-1.39,8h-2.3l2.44-14.17Z"
        />
        <Path
          fill="white"
          d="M150.66,11.78c4.23,0,6.68,3,5.93,7.43a8.62,8.62,0,0,1-8.47,7.32c-4.15,0-6.66-3-5.92-7.32A8.69,8.69,0,0,1,150.66,11.78Zm-.34,2.15a6,6,0,0,0-5.82,5.28c-.51,3,1.17,5.11,4,5.11a6,6,0,0,0,5.81-5.11C154.86,16.06,153.09,13.93,150.32,13.93Z"
        />
        <Path
          fill="white"
          d="M161.13,12.07h2.1L163,14.34a6.8,6.8,0,0,1,5.16-2.47c3.5,0,5.67,2.41,5,6.57l-1.35,7.83h-2.3l1.35-7.81c.47-2.73-.81-4.42-3.45-4.42a5.39,5.39,0,0,0-5.06,4.62l-1.31,7.6h-2.27l2.44-14.17Z"
        />
        <Path
          fill="white"
          d="M178.18,12h2.3L178,26.27h-2.3Zm.4-4.27c-.36,2.07,2.67,2.07,3,0S178.93,5.73,178.58,7.77Z"
        />
        <Path
          fill="white"
          d="M188.57,12.13h4.29l-.35,2h-4.32l-1.29,7.49c-.29,1.66.06,2.73,1.72,2.73a5,5,0,0,0,2-.52l.34,2a7.53,7.53,0,0,1-2.81.69c-3,.06-4.08-1.72-3.54-4.85l1.29-7.49h-2.77l.35-2h2.77l.67-3.9L189.29,8Z"
        />
        <Path
          fill="white"
          d="M202.14,11.78c4.23,0,6.68,3,5.93,7.43a8.61,8.61,0,0,1-8.47,7.32c-4.15,0-6.66-3-5.92-7.32A8.69,8.69,0,0,1,202.14,11.78Zm-.34,2.15A6,6,0,0,0,196,19.21c-.51,3,1.17,5.11,4,5.11a6,6,0,0,0,5.82-5.11C206.34,16.06,204.58,13.93,201.8,13.93Z"
        />
        <Path
          fill="white"
          d="M214.77,12.07l-.2,2.12a5.4,5.4,0,0,1,4.67-2.35,4.42,4.42,0,0,1,3,1.06l-1.38,2a3.38,3.38,0,0,0-2.28-.77,5,5,0,0,0-4.8,4.25l-1.36,7.89h-2.3l2.44-14.17h2.13Z"
        />
        <Path
          fill="white"
          d="M15.08,19.12l-8.8-5.95a1.63,1.63,0,0,1-.5-2.09l1.7-3.14A2.68,2.68,0,0,1,9.61,6.67H29.54A16.28,16.28,0,0,0,16.43,0,16.63,16.63,0,0,0,0,16.81a17.26,17.26,0,0,0,.29,3.11H14.84C15.63,19.92,15.74,19.56,15.08,19.12Z"
        />
        <Path
          fill="white"
          d="M17.79,13.17l8.8,5.95a1.64,1.64,0,0,1,.5,2.09l-1.7,3.14a2.68,2.68,0,0,1-2.12,1.28H2.43a16.35,16.35,0,0,0,14,8A16.63,16.63,0,0,0,32.87,16.81a17.19,17.19,0,0,0-.59-4.45H18C17.24,12.36,17.13,12.72,17.79,13.17Z"
        />
      </Svg>
      /*            </View>*/
    );
  }
}
