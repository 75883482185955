import { teamActions, roleActions } from "../actions";
import * as initial_state_json from "../initial_state.json";
import ResourceCache from "../classes/resourcecache";

const initialstate = initial_state_json.teams || {}; //Team.fromData(initial_state_json.state.teams || {});

export default function team(state = initialstate, action) {
  switch (action.type) {
    case roleActions.RESET_ROLES: {
      return ResourceCache.addTimeStamp(action.json.teams);
    }

    case teamActions.FETCH_TEAMS_SUCCESS: {
      return ResourceCache.addTimeStamp(action.json.teams);
    }

    case teamActions.ADD_TEAM: {
      let team = {};
      team[action.team.id] = ResourceCache.addTimeStamp(action.team);
      return { ...state, ...team };
    }

    case teamActions.UPDATE_TEAM: {
      let team = {};
      team[action.team.id] = ResourceCache.addTimeStamp(action.team);
      return { ...state, ...team };
    }

    case teamActions.REMOVE_TEAM: {
      let teams = Object.assign({}, state);
      delete teams[action.team.id];
      return teams;
    }

    default:
      return state;
  }
}
