import datastore from "../datasource";

export const SET_ORGANIZATION = "SET_ORGANIZATION";

export function initOrganization() {
  return (dispatch) => {
    fetch(datastore.get())
      .then(
        (response) => {
          return response.json();
        },
        (error) => {
          throw new Error("HTTP error " + error.status);
        }
      )
      .then((json) => {
        dispatch(setOrganization(json.organization));
      });
  };
}

export function setOrganization(organization) {
  return { type: SET_ORGANIZATION, organization };
}
