import Constants from "expo-constants";
import { CacheableResource } from "../types";

export default class ResourceCache {
  public static expiresTimeStamp() {
    return ResourceCache.enabled()
      ? Constants.manifest.extra.cacheExpiresIn + Date.now()
      : Date.now();
  }
  public static addTimeStamp(resource: CacheableResource) {
    resource.expires = ResourceCache.enabled()
      ? Constants.manifest.extra.cacheExpiresIn + Date.now()
      : Date.now();
    return resource;
  }
  public static enabled() {
    return Constants.manifest.extra.cacheResources;
  }
  public static expired(resource) {
    if (!ResourceCache.enabled) {
      return false;
    }
    if (typeof resource.expires !== "number") {
      return true;
    }
    return resource.expires < Date.now();
  }
}
