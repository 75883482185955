//import datastore from "../datasource";
import { roleActions } from "../actions";
import * as server from "../server/server";
import { requestAccessToken, refreshAccessToken } from "../server/oauth";
import { getAccessToken } from "../server/server";
import { reduxStore } from "../store";

export const SET_USER = "SET_USER";
export const SET_CURRENT_ROLE = "SET_CURRENT_ROLE";

//Session Authorization
export const AUTHORIZE_SUCCESS = "AUTHORIZE_SUCCESS";
export const AUTHORIZE = "AUTHORIZE";
export const AUTHORIZE_FAIL = "AUTHORIZE_FAIL";

// OAuth Authorization
export const OAUTH_REQUEST_ACCESS_TOKEN = "OAUTH_REQUEST_ACCESS_TOKEN";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

export function setAccessToken(response) {
  console.log(response);
}

// Debug console testapi
// http://testapi.sportmonitor.net/debug/default/index

export enum Scenario {
  Registration,
  AdminFirstLogin,
  AdminNoTeam,
  AdminNoPlayers,
  AdminNoReport,
  RespondentFirstLogin,
}
const Scenarios: {
  title: string;
  jsonFile: string;
}[] = [];
Scenarios[Scenario.AdminFirstLogin] = {
  title: "Eerste keer inloggen beheerder",
  jsonFile: "admin_first_login",
};
Scenarios[Scenario.AdminNoTeam] = {
  title: "Team gemaakt, nog geen spelers",
  jsonFile: "admin_no_team",
};
Scenarios[Scenario.AdminNoPlayers] = {
  title: "Spelers toegevoegd, nog geen beoordelaars",
  jsonFile: "admin_no_players",
};
Scenarios[Scenario.AdminNoReport] = {
  title: "Beoordelaars toegevoegd, nog geen rapportage gemaakt",
  jsonFile: "admin_no_report",
};
Scenarios[Scenario.Registration] = {
  title: "Registratie (Nieuwe gebruiker)",
  jsonFile: "registration",
};
Scenarios[Scenario.RespondentFirstLogin] = {
  title: "Beheerder en respondent",
  jsonFile: "respondent_first_login",
};

// http://testapi.sportmonitor.net/debug/default/index
export function oauthRefreshAccessToken() {
  console.log("oauthRefreshAccessToken");
  refreshAccessToken(
    {
      action: handleAccesstokenSuccess,
    },
    {
      action: handleError,
    }
  );
}

// http://testapi.sportmonitor.net/debug/default/index
export function oauthRequestAccessToken(response, request) {
  console.log("oauthRequestAccessToken");
  requestAccessToken(
    response,
    request,
    {
      action: handleAccesstokenSuccess,
    },
    {
      action: handleError,
    }
  );
}

async function handleResponse(response, params) {
  // dispatch, navigation, response) {
  //console.log(response);
  response.json().then((json) => {
    if (json.success === 1) {
      reduxStore.dispatch({ type: "AUTHORIZE_SUCCESS", user: json });
      //console.log("actions\\user.js::authorize() => response: success");
      console.log(json);
      console.log(json.code);
      server.setAccessToken(json.code).then(() => {
        roleActions.get_roles();
        params.navigation.reset({
          index: 0,
          routes: [{ name: "root" }],
        });
      });
    } else {
      params.dispatch({ type: "AUTHORIZE_FAILED", user: json });
      //console.log("actions\\user.js::authorize() => response: failed");
      //console.log(json);
      // todo: navigate to login page
      if (json.error) {
        params.navigation.reset({
          index: 0,
          routes: [{ name: "LoginScreen", params: { error: json.error } }],
        });
      } else {
        // error code not set
        // this should not happen
        params.navigation.reset({
          index: 0,
          routes: [{ name: "LoginScreen" }],
        });
      }
    }
  });
}

async function handleAccesstokenSuccess(response, params) {
  // (dispatch, navigation, error) {
  // TODO error: handle
  // some error
  // navigation to login page
  console.log("handleAccesstokenSuccess");
  response.json().then((json) => {
    console.log(json);
    /*
    const access_token = json.access_token;
    const expires_in = json.expires_in;
    const refresh_token = json.refresh_token;
    const token_type = json.token_type;
    */
    debugger;
    server
      .setAccessToken(json.access_token, json.refresh_token, json.expires_in)
      .then(() => {
        debugger;
        reduxStore.dispatch({ type: "SET_ACCESS_TOKEN", payload: json });
        roleActions.get_roles();
      });
  });
}

async function handleSuccess(response, params) {
  // (dispatch, navigation, error) {
  // TODO error: handle
  // some error
  // navigation to login page
  console.log("handleSuccess");
  response.json().then((json) => {
    console.log(json);
    /*
    const access_token = json.access_token;
    const expires_in = json.expires_in;
    const refresh_token = json.refresh_token;
    const token_type = json.token_type;
    */
    params.dispatch({ type: "SET_ACCESS_TOKEN", payload: json });
  });
}

async function handleError(error, params) {
  // (dispatch, navigation, error) {
  // TODO error: handle
  // some error
  // navigation to login page
  console.log("handleError");
  alert(error);
}

export function loadScenario(scenario: Scenario, navigation: any) {
  return (dispatch) => {
    navigation.reset({
      index: 0,
      routes: [{ name: "loading" }],
    });
    fetch("/static_api/" + Scenarios[scenario].jsonFile + ".json", {})
      .then((response) => handleResponse(response, { dispatch, navigation }))
      .catch((error) => handleError(error, { dispatch, navigation }));
  };
  // response = Response {
  // type: "cors",
  // url: "https://testapi.sportmonitor.net/authorize/spa?res…ode&client_id=sportmonitor_spa_test&state=1234567",
  // redirected: false,
  // status: 200,
  // ok: true, …}
}

export function setCurrentRole(roleUuid: string) {
  return { type: SET_CURRENT_ROLE, roleUuid: roleUuid };
}

/*
export function initUser() {
  return (dispatch) => {
    fetchUserFromServer()
      .then(
        (response) => {
          if (response.ok) return response.json();
          else throw new Error(JSON.stringify(response));
        },
        (response) => {
          alert(JSON.stringify(response));
          throw new Error("HTTP error " + response.status);
        }
      )
      .then((json) => {
        dispatch(setUser(json.user));
      })
      .catch((err) => {
        alert(err);
      });
  };
}
*/

export function setUser(user) {
  return { type: SET_USER, user };
}
