import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers"; //'./redux/reducers';
import CryptoJS from "crypto-js";
import Constants from "expo-constants";
/* global __DEV__ */

// persist
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "./storage"; // sessionStorage for web, expo-file-system-storage for native
import { PersistGate } from "redux-persist/integration/react";
import PersistLoading from "../screens/PersistLoading";
//import createEncryptor from "redux-persist-transform-encrypt";

import {
  checkInternetConnection,
  createNetworkMiddleware,
  offlineActionCreators,
  ReduxNetworkProvider,
} from "../proxy/react-native-offline/src"; // "react-native-offline";

let code = (function () {
  // encryption
  return {
    encryptMessage: function (messageToencrypt = "", secretkey = "") {
      const encryptedMessage = CryptoJS.AES.encrypt(
        messageToencrypt,
        secretkey
      );
      return encryptedMessage.toString();
    },
    decryptMessage: function (encryptedMessage = {}, secretkey = "") {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedMessage, secretkey);
      return decryptedBytes.toString(CryptoJS.enc.Utf8);
    },
  };
})();

const DataRecordTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (
    inboundState
    // key
  ) => {
    // TODO: Enable encryption in production
    if (Constants.manifest.extra.encryption) {
      // encrypt
      return {
        enc: code.encryptMessage(JSON.stringify(inboundState), "your_password"),
      };
    } else {
      // for now we return unencrypted state
      return inboundState;
    }
  },
  // transform state being rehydrated
  (outboundState, key) => {
    let outstate = {};
    if (outboundState["enc"]) {
      outstate = JSON.parse(
        code.decryptMessage(outboundState["enc"], "your_password")
      );
    } else outstate = outboundState;
    return outstate;
  },
  // define which reducers this transform gets called for.
  {}
);

const persistConfig = {
  key: "root",
  blacklist: ["session", "network"],
  // version from app.json
  version: Constants.manifest.extra.reduxStoreVersion,

  storage,
  debug: true, // todo: set debug to false
  //    migrate: createMigrate(migrations, { debug: false }),
  //    blacklist: ['theme'] // theme will not be persisted
  transforms: [DataRecordTransform /*encryptor */],
  //  stateReconciler: autoMergeLevel1,
};
const { connectionChange } = offlineActionCreators;
const persistedReducer = persistReducer(persistConfig, rootReducer);
let reduxStore;
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const networkMiddleware = createNetworkMiddleware();
reduxStore = createStore(
  persistedReducer,
  undefined,
  //initial_state,
  composeEnhancers(applyMiddleware(networkMiddleware, thunk))
);

let persistedStore = persistStore(reduxStore, null, () => {
  // After rehydration completes, we detect initial connection
  checkInternetConnection().then((isConnected) => {
    reduxStore.dispatch(connectionChange(isConnected));
    //callback(); // Notify our root component we are good to go, so that we can render our app
  });
});

if (module.hot) {
  module.hot.accept("./reducers", () => {
    // This fetch the new state of the above reducers.
    const nextRootReducer = require("./reducers").default;
    reduxStore.replaceReducer(persistReducer(persistConfig, nextRootReducer));
  });
}

export { reduxStore, persistedStore };

export default (props) => (
  <Provider store={reduxStore}>
    <PersistGate loading={<PersistLoading />} persistor={persistedStore}>
      <ReduxNetworkProvider
      //        pingServerUrl={Platform.OS == "web" ? "/" : "https://www.google.com/"}
      >
        {props.children}
      </ReduxNetworkProvider>
    </PersistGate>
  </Provider>
);
