import * as server from "../server/server";

export const SET_ANSWERS = "SET_ANSWERS";
export const GET_ANSWERS = "GET_ANSWERS";

export function setAnswers(answers) {
  return { type: SET_ANSWERS, answers };
}

export function getAnswers(params: {} = {}) {
  return (/*dispatch*/) => {
    server.resourceGet(server.apiResource.answer, 769809, params, {});
  };
}
